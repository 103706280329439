import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Section, SectionHeader, SectionContent, SectionColumn } from "@layouts/Section"
import * as Spacer from "@atoms/Spacer"
import Seo from "@components/atoms/Seo"
import { MeetList } from "@projects/MeetList"
import ListController from "@projects/ListController"
import Border from "@atoms/Border"

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

//ログイン後のindexページ
//ログインしてない場合は全部indexにリダイレクト
function NewsListPage({data, pageContext}) {
    console.log(data, pageContext)
    const { meet, status, ogpImage } = data

    let statusOptions = status.group.map(({ fieldValue }, i) => {
        let values = fieldValue.split(":")
        return {
            value: values[0],
            label: values[1],
        }
    })
    statusOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    let yearsOptions = pageContext.years.map((val)=>{
        return {
            value: val,
            label: val,
        }
    })
    yearsOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    return (
        <>
            <Seo
                subtitle="Meet"
                image={ogpImage.publicURL}
            />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="Meet"
                    text="チケット情報"
                />
                <SectionContent>
                    <SectionColumn>
                        <ListController label="期間"
                            parent="member/meet"
                            current={pageContext.year}
                            options={yearsOptions}
                            sortType="year"
                        />
                        <ListController label="ステータス"
                            parent="member/meet"
                            current={pageContext.status.split(":")[1]}
                            options={statusOptions}
                            sortType="category" />
                    </SectionColumn>
                    <Border />
                    <Router basepath="/member/meet">
                        <PrivateRoute path="/" component={MeetList} data={meet.edges}/>
                        <PrivateRoute path="/:p1" component={MeetList} data={meet.edges} />
                        <PrivateRoute path="/:p1/:p2" component={MeetList} data={meet.edges} />
                    </Router>
                </SectionContent>
                {/* <SectionMoreLink
                    title="More"
                    text="もっとみる"
                    to="/news/"
                /> */}
            </Section>
        </>
    )
}

export const pageQuery = graphql`
	query MeetList(
        $statusReg: String!,
        $periodStartDate: Date!,
        $periodEndDate: Date!
    ){
		meet: allWpMeet(
			filter: {
                acf_meet :{ status : { regex: $statusReg } }
                date: { gte: $periodStartDate, lt: $periodEndDate }
            }
			limit: 9999
			sort: { fields: [date], order: DESC }) {
			edges {
				node {
					...Meet
				}
			}
		}
        status : allWpMeet {
            group(field: acf_meet___status) {
                fieldValue
                totalCount
            }
        }
        ogpImage : file(relativePath: { eq: "OGP_meet.png" }) {
            publicURL
        }
	}
`
export default NewsListPage